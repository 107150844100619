<template>
  <div>
    <CRow v-if="bells != null">
      <CCol sm="12">
        <CTableWrapper
          :items="data"
          :fields="fields"
          hover
          :striped="true"
          :border="true"
          small
          fixed
          caption="Lista de proveedores"
          icon="fas fa-dolly"
          btn_name="proveedor"
          :size="'md'"
          :button_new="false"
          :actions="true"
          :withActions="'6%'"
          :buttonEdit="false"
          :buttonDelete="false"
          :myButtons="myButtons"
          @mtd_estimation="mtd_estimation"
        />
      </CCol>
    </CRow>

    <CRow v-else>
      <CCol col="12">
        <CCard>
          <CCardHeader> CAMPAÑA NO INICIADA </CCardHeader>
          <CCardBody>
            COMUNIQUESE CON EL SUPERADMINISTRADOR PARA INICIAR UNA CAMPAÑA Y
            PODER CONTINUAR.
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <!-- modal -->
    <CModalForm
      :size="'md'"
      :title="modal.title"
      :button="cp_button"
      :show.sync="modal.modal_form"
      @mtd_action="mtd_action"
    >
      <CRow>
        <CCol col="12">
          <CInput
            label="Estimado"
            placeholder="Digite cantidad estimada"
            v-model="estimate.amount"
          />
        </CCol>
      </CRow>
    </CModalForm>
  </div>
</template>


<script>
const fields = [
  { key: "Id", _style: "width:3%" },
  { key: "document", label: "Documento", _style: "width:10%;" },
  { key: "name", label: "Nombre", _style: "width:20%;" },
  { key: "amount", label: "Estimado(Kg)", _style: "width:10%;" },
  { key: "acopio", label: "Monto acopiado", _style: "width:20%;" },
  { key: "acopio_dif", label: "Falta acopio", _style: "width:20%;" },
  { key: "status", label: "Estado", _style: "width:5%;" },
];

import CTableWrapper from "../../components/shared/datatable/Table.vue";
import CModalForm from "../../components/shared/modals/cModalForm.vue";
import cModalDelete from "../../components/shared/modals/cModalDelete.vue";
import { mapActions } from "vuex";
import { bus } from "../../main";

import cSelectForm from "../../components/shared/inputs/cSelect.vue";

export default {
  components: { CTableWrapper, CModalForm, cModalDelete, cSelectForm },
  data() {
    return {
      prefix: "estimate",
      fields,
      bells: null,
      data: [],
      myButtons: [
        {
          class: "btn-sm",
          color: "success",
          tooltip: "Agregar estimación",
          action: "mtd_estimation",
          icon: "fas fa-plus",
        },
      ],
      modal: {
        action: "",
        title: "",
        modal_form: false,
      },
      estimate: {
        bell_id: "",
        supplier_id: "",
        amount: "",
      },
    };
  },
  computed: {
    cp_button: function () {
      if (this.estimate.amount != "") return false;
      return true;
    },
  },
  created() {
    this.mtd_getdata();
  },
  methods: {
    ...mapActions(["get", "post"]),
    mtd_getdata: function () {
      this.get({
        url: this.$store.getters.get__url + "/" + this.prefix,
        token: this.$store.getters.get__token,
      })
        .then((response) => {
          this.data = response.data.map((item, order) => {
            return { ...item, order };
          });
          this.bells = response.bells;
        })
        .catch((errors) => {
          // this.errorsBackend = errors;
          // this.$emit("error", this.event);
        });
    },
    mtd_open_modal: function (boo, action, name) {
      this.modal.modal_form = boo;
      this.modal.action = action;
      action == "store"
        ? (this.modal.title = "Agregar estimación para " + name)
        : (this.modal.title = "Editar estimación para " + name);
    },
    mtd_estimation: function (item) {
      let action = item.amount == 0 ? "store" : "update";
      this.mtd_open_modal(true, action, item.name);
      this.estimate = {
        bell_id: "",
        supplier_id: item.Id,
        amount: "",
      };
    },
    mtd_action: function () {
      let boo = false;
      if (this.modal.action == "store") {
        boo = true;
      } else {
        let cc = confirm("¿DESEA ACTUALIZAR LA ESTIMACIÓN?");
        boo = cc == true ? true : false;
      }
      if (boo == true) {
        this.post({
          url: this.$store.getters.get__url + "/" + this.prefix + "/store",
          token: this.$store.getters.get__token,
          params: this.estimate,
        })
          .then((response) => {
            console.log(response);
            if (response.state == 0) {
              bus.$emit("alert", {
                color: "success",
                message: "PRODUCCIÓN ESTIMADA GUARDADA CORRECTAMENTE!!",
              });
              this.data.forEach((element) => {
                console.log(element);
                if (element.Id == this.estimate.supplier_id) {
                  element.amount = parseFloat(response.data.amount).toFixed(2);
                }
              });
              this.estimate = {
                bell_id: "",
                supplier_id: "",
                amount: "",
              };
              this.mtd_open_modal(false, "", "");
            } else {
              bus.$emit("alert", {
                color: "danger",
                message: response.exception,
              });
            }
          })
          .catch((errors) => {
            bus.$emit("alert", {
              color: "danger",
              message: errors,
            });
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-search {
  color: #fff;
  background-color: #2819ae;
  border-color: #2517a3;
}
</style>